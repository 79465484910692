.wrapper-preloader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100px;
    z-index: 99999999999999;
}
.loading-container {
    width: 100%;
    text-align: center;
    color: #fff;
    position: relative;
}
.loading-container:before {
     content: '';
     position: absolute;
     width: 100%;
     height: 3px;
     background-color: #fff;
     bottom: 0;
     left: 0;
     border-radius: 10px;
     animation: movingLine 2.4s infinite ease-in-out;
 }

@keyframes movingLine {
    0% {
        opacity: 0;
        width: 0;
    }

    33.3%, 66% {
        opacity: 0.8;
        width: 100%;
    }

    85% {
        width: 0;
        left: initial;
        right: 0;
        opacity: 1;
    }

    100% {
        opacity: 0;
        width: 0;
    }
}

.loading-text {
    font-size: 5vw;
    line-height: 64px;
    letter-spacing: 4px;
    display: flex;
    justify-content: space-evenly;
}
@media screen and (min-width: 768px) {
    .loading-text {
        line-height: 164px;
    }
}
.loading-text span {
    animation: moveLetters 2.4s infinite ease-in-out;
    transform: translatex(0);
    position: relative;
    display: inline-block;
    opacity: 0;
    text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
    color: #fff;
}

.loading-text span:nth-child(1) {
    animation-delay: 0.1s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.2s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.3s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.4s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.5s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.6s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.7s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.8s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.9s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.10s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.11s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.12s
}
.loading-text span:nth-child(1) {
    animation-delay: 0.13s
}

@keyframes moveLetters {
    0% {
        transform: translateX(-15vw);
        opacity: 0;
    }

    33.3%, 66% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(15vw);
        opacity: 0;
    }
}

